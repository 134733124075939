import { BASE_URL } from 'store/constant';

function querystring(query = {}) {
    const qs = Object.entries(query)
        .filter((pair) => pair[1] !== undefined)
        .map((pair) =>
            pair
                .filter((i) => i !== null)
                .map(encodeURIComponent)
                .join('=')
        )
        .join('&');

    return qs && `?${qs}`;
}

export default class Fetch {
    fetchRequest = async (method, url, params = {}) => {
        url = BASE_URL + url;
        const headers = this.authHeader(url);
        const requestOptions = {
            method,
            headers
        };
        if (method === 'GET') {
            url = `${url}${querystring(params)}`;
        } else {
            const body = JSON.stringify(params);
            requestOptions.body = body;
        }
        const response = await fetch(url, requestOptions);
        return response.json();
    };

    authHeader = () => {
        const Header = { 'Content-Type': 'application/json' };

        try {
            const admin = localStorage.getItem('admin');
            if (admin) {
                const unstringfyData = JSON.parse(admin);
                const { jwtToken } = unstringfyData;
                if (jwtToken) {
                    Header.Authorization = jwtToken;
                }
                return Header;
            }
            return Header;
        } catch (error) {
            return Header;
        }
    };
}
