import * as ACTIONS from 'store/actions';

const initialState = {
    addProfessionLoading: false,
    addPostTagLoading: false,
    addProfessionServiceLoading: false,
    addServiceLoction: false,
    addLoanProvider: false,
    getProfessionsLoading: false,
    getProfessionServicesLoading: false,
    getPostTagsLoading: false,
    getServiceLocationsLoading: false,
    getLoanProvidersLoading: false,
    getLeadsLoading: false,
    getLeadDetailsLoading: false,
    professions: [],
    professionServices: [],
    postTags: [],
    serviceLocations: [],
    loanProviders: [],
    leads: [],
    leadDetails: {},
    reports: [],
    reportsLoading: false,
    updateReportStatusLoading: false,
    insightLoading: false,
    insightCount: [],
    pdInsightLoading: false,
    pdInsight: {},
    zonesLoading: false,
    zones: [],
    lmactivityLoading: false,
    lmactivities: [],
    mInventoryItemsLoading: false,
    mInventoryItems: [],
    mInventoryLeadsLoading: false,
    mInventoryLeads: [],
    inquiriesLoading: false,
    inquiries: [],
    totalInquiries: 0
};

const utilReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ADD_PROFESSION_START:
            return {
                ...state,
                addProfessionLoading: true
            };
        case ACTIONS.ADD_PROFESSION_SUCCESS:
            return {
                ...state,
                addProfessionLoading: false
            };
        case ACTIONS.ADD_PROFESSION_FAIL:
            return {
                ...state,
                addProfessionLoading: false
            };
        case ACTIONS.ADD_POST_TAGS_START:
            return {
                ...state,
                addPostTagLoading: true
            };
        case ACTIONS.ADD_POST_TAGS_SUCCESS:
            return {
                ...state,
                addPostTagLoading: false
            };
        case ACTIONS.ADD_POST_TAGS_FAIL:
            return {
                ...state,
                addPostTagLoading: false
            };
        case ACTIONS.ADD_PROFESSION_SERVICE_START:
            return {
                ...state,
                addProfessionServiceLoading: true
            };
        case ACTIONS.ADD_PROFESSION_SERVICE_SUCCESS:
            return {
                ...state,
                addProfessionServiceLoading: false
            };
        case ACTIONS.ADD_PROFESSION_SERVICE_FAIL:
            return {
                ...state,
                addProfessionServiceLoading: false
            };
        case ACTIONS.ADD_SERVICE_LOCATIONS_START:
            return {
                ...state,
                addServiceLoction: true
            };
        case ACTIONS.ADD_SERVICE_LOCATIONS_SUCCESS:
            return {
                ...state,
                addServiceLoction: false
            };
        case ACTIONS.ADD_SERVICE_LOCATIONS_FAIL:
            return {
                ...state,
                addServiceLoction: false
            };
        case ACTIONS.ADD_LOAN_PROVIDER_START:
            return {
                ...state,
                addLoanProvider: true
            };
        case ACTIONS.ADD_LOAN_PROVIDER_SUCCESS:
            return {
                ...state,
                addLoanProvider: false
            };
        case ACTIONS.ADD_LOAN_PROVIDER_FAIL:
            return {
                ...state,
                addLoanProvider: false
            };
        case ACTIONS.GET_PROFESSIONS_START:
            return {
                ...state,
                getProfessionsLoading: true
            };
        case ACTIONS.GET_PROFESSIONS_SUCCESS:
            return {
                ...state,
                getProfessionsLoading: false,
                professions: action.data
            };
        case ACTIONS.GET_PROFESSIONS_FAIL:
            return {
                ...state,
                getProfessionsLoading: false
            };
        case ACTIONS.GET_PROFESSION_SERVICES_START:
            return {
                ...state,
                getServiceLocationsLoading: true
            };
        case ACTIONS.GET_PROFESSION_SERVICES_SUCCESS:
            return {
                ...state,
                getServiceLocationsLoading: false,
                professionServices: action.data
            };
        case ACTIONS.GET_PROFESSION_SERVICES_FAIL:
            return {
                ...state,
                getServiceLocationsLoading: false
            };
        case ACTIONS.GET_SERVICE_LOCATIONS_START:
            return {
                ...state,
                getServiceLocationsLoading: true
            };
        case ACTIONS.GET_SERVICE_LOCATIONS_SUCCESS:
            return {
                ...state,
                getServiceLocationsLoading: false,
                serviceLocations: action.data
            };
        case ACTIONS.GET_SERVICE_LOCATIONS_FAIL:
            return {
                ...state,
                getServiceLocationsLoading: false
            };
        case ACTIONS.GET_POST_TAGS_START:
            return {
                ...state,
                getPostTagsLoading: true
            };
        case ACTIONS.GET_POST_TAGS_SUCCESS:
            return {
                ...state,
                getPostTagsLoading: false,
                postTags: action.data
            };
        case ACTIONS.GET_POST_TAGS_FAIL:
            return {
                ...state,
                getPostTagsLoading: false
            };
        case ACTIONS.GET_LOAN_PROVIDERS_START:
            return {
                ...state,
                getLoanProvidersLoading: true
            };
        case ACTIONS.GET_LOAN_PROVIDERS_SUCCESS:
            return {
                ...state,
                getLoanProvidersLoading: false,
                loanProviders: action.data
            };
        case ACTIONS.GET_LOAN_PROVIDERS_FAIL:
            return {
                ...state,
                getLoanProvidersLoading: false
            };

        case ACTIONS.GET_LEADS_START:
            return {
                ...state,
                getLeadsLoading: true
            };
        case ACTIONS.GET_LEADS_SUCCESS:
            const leads = action.data.leadsData;
            leads[0].count = action.data.totalLeads;
            return {
                ...state,
                getLeadsLoading: false,
                leads
            };
        case ACTIONS.GET_LEADS_FAIL:
            return {
                ...state,
                getLeadsLoading: false,
                leads: []
            };
        case ACTIONS.GET_LEAD_DETAILS_START:
            return {
                ...state,
                getLeadDetailsLoading: true
            };
        case ACTIONS.GET_LEAD_DETAILS_SUCCESS:
            return {
                ...state,
                getLeadDetailsLoading: false,
                leadDetails: action.data
            };
        case ACTIONS.GET_LEAD_DETAILS_FAIL:
            return {
                ...state,
                getLeadDetailsLoading: false
            };

        case ACTIONS.GET_REPORTS_START:
            return {
                ...state,
                reportsLoading: true
            };
        case ACTIONS.GET_REPORTS_SUCCESS:
            return {
                ...state,
                reportsLoading: false,
                reports: action.data
            };
        case ACTIONS.GET_REPORTS_FAIL:
            return {
                ...state,
                reportsLoading: false,
                reports: [],
            };

        case ACTIONS.UPDATE_REPORT_STATUS_START:
            return {
                ...state,
                updateReportStatusLoading: true
            };
        case ACTIONS.UPDATE_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                updateReportStatusLoading: false,
            };
        case ACTIONS.UPDATE_REPORT_STATUS_FAIL:
            return {
                ...state,
                updateReportStatusLoading: false
            };


        case ACTIONS.GET_INSIGHTS_START:
            return {
                ...state,
                insightLoading: true
            };
        case ACTIONS.GET_INSIGHTS_SUCCESS:
            return {
                ...state,
                insightLoading: false,
                insightCount: action.data
            };
        case ACTIONS.GET_INSIGHTS_FAIL:
            return {
                ...state,
                insightLoading: false
            };

        case ACTIONS.GET_POST_DESIGN_INSIGHTS_START:
            return {
                ...state,
                pdInsightLoading: true
            };
        case ACTIONS.GET_POST_DESIGN_INSIGHTS_SUCCESS:
            return {
                ...state,
                pdInsightLoading: false,
                pdInsight: action.data
            };
        case ACTIONS.GET_POST_DESIGN_INSIGHTS_FAIL:
            return {
                ...state,
                pdInsightLoading: false,
                pdInsight: {}
            };

        case ACTIONS.GET_ZONES_START:
            return {
                ...state,
                zonesLoading: true
            };
        case ACTIONS.GET_ZONES_SUCCESS:
            return {
                ...state,
                zonesLoading: false,
                zones: action.data.zones,
                zonesCount: action.data.count,
                zonesPage: action.data.page,
            };
        case ACTIONS.GET_ZONES_FAIL:
            return {
                ...state,
                zonesLoading: false,
                zones: []
            };

        case ACTIONS.GET_LEAD_MASTER_ACTIVITY_START:
            return {
                ...state,
                lmactivityLoading: true
            };
        case ACTIONS.GET_LEAD_MASTER_ACTIVITY_SUCCESS:
            return {
                ...state,
                lmactivityLoading: false,
                lmactivities: action.data,
            };
        case ACTIONS.GET_LEAD_MASTER_ACTIVITY_FAIL:
            return {
                ...state,
                lmactivityLoading: false,
                lmactivities: []
            };
        case ACTIONS.GET_INVENTORY_ITEMS_START:
            return {
                ...state,
                mInventoryItemsLoading: true
            };
        case ACTIONS.GET_INVENTORY_ITEMS_SUCCESS:
            return {
                ...state,
                mInventoryItemsLoading: false,
                mInventoryItems: action.data,
            };
        case ACTIONS.GET_INVENTORY_ITEMS_FAIL:
            return {
                ...state,
                mInventoryItemsLoading: false,
                mInventoryItems: []
            };
        case ACTIONS.GET_INVENTORY_LEADS_START:
            return {
                ...state,
                mInventoryLeadsLoading: true
            };
        case ACTIONS.GET_INVENTORY_LEADS_SUCCESS:
            return {
                ...state,
                mInventoryLeadsLoading: false,
                mInventoryLeads: action.data,
            };
        case ACTIONS.GET_INVENTORY_LEADS_FAIL:
            return {
                ...state,
                mInventoryLeadsLoading: false,
                mInventoryLeads: []
            };

        case ACTIONS.GET_INQUIRIES_START:
            return {
                ...state,
                inquiriesLoading: true
            };
        case ACTIONS.GET_INQUIRIES_SUCCESS:
            return {
                ...state,
                inquiriesLoading: false,
                inquiries: action.data.inquiries,
                totalInquiries: action.data.count
            };
        case ACTIONS.GET_INQUIRIES_FAIL:
            return {
                ...state,
                inquiriesLoading: false,
                inquiries: [],
            };

        default:
            return state;
    }
};
export default utilReducer;
