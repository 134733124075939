// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const BASE_URL = 'https://admin.verko.in/api/admin/v1/';
export const ROOT = 'https://admin.verko.in/';
export const ADMIN_LOGIN = 'auth/login';
export const UNREGISTER = 'auth/unRegisterDevice';

export const ADD_PROFESSION = 'util/addProfession';
export const ADD_POST_TAGS = 'util/addPostTag';
export const ADD_PROFESSION_SERVICE = 'util/addProfessionService';
export const ADD_SERVICE_LOCATIONS = 'util/addServiceLocations';
export const ADD_LOAN_PROVIDER = 'util/addLoanProvider';

export const GET_PROFESSIONS = 'util/getProfessions';
export const GET_PROFESSION_SERVICES = 'util/getProfessionServices';
export const GET_SERVICE_LOCATIONS = 'util/getServiceLocations';
export const GET_POST_TAGS = 'util/getPostTags';
export const GET_LOAN_PROVIDERS = 'util/getLoanProviders';
export const GET_LEADS = 'util/getLeads';
export const GET_LEAD_DETAILS = 'util/getLeadDetails';
export const ADD_LEAD_ACTIVITY  = 'util/addLeadActivity';
export const DELETE_LEAD_STATUS = 'util/deleteLeadStatus';
export const UPDTAE_LEAD_ACTIVITY_STATUS = 'util/updateLeadActivityStatus';
export const GET_REPORTS = 'util/getReports';
export const UPDATE_REPORT_STATUS = 'util/updateReportStatus';
export const GET_INSIGHTS = 'util/getInsights';
export const GET_POST_DESIGN_INSIGHTS = 'util/getPdInsights';
export const CREATE_ZONE = 'util/createZone';
export const GET_ZONES = 'util/getZones';
export const CREATE_LEAD_MASTER_ACTIVITY = 'util/createLeadMasterActivity';
export const GET_LEAD_MASTER_ACTIVITY = 'util/getLeadMasterActivity';
export const GET_INQUIRIES = 'util/getInquiries';

export const GET_DISCUSSION_POSTS = 'discussion/getDiscussionPosts';
export const VERIFY_DISCUSSION_POST = 'discussion/verifyPost';
export const DISCUSSION_POST_REJECT = 'discussion/rejectPost';
export const CHANGE_DISCUSSION_STATUS = 'discussion/changeDiscussionStatus';
export const GET_DISCUSSIONS = 'discussion/getDiscussionPosts';
export const GET_USER_DISCUSSIONS = 'discussion/getUserDiscussions';
export const GET_DISCUSSION_COMMENTS = 'discussion/getComments';
export const ADD_DISCUSSION_COMMENT = 'discussion/addComment';
export const ADD_DISCUSSION_REACT = 'discussion/reactOnDiscussion';
export const ADD_DISCUSSION_REPLY = 'discussion/addReply';

export const GET_POSTS = 'post/getPosts';
export const GET_REVIEWED_POSTS = 'post/getReviewedPosts';
export const VERIFY_POST = 'post/verifyPost';
export const POST_REJECT = 'post/rejectPost';

export const GET_USERS = 'user/getUsers';
export const GET_USERS_FOR_AUTO_FOLLOW = 'user/getUsersForAutoFollow';
export const CREATE_USER = 'user/createUser';
export const GET_ADMINS = 'user/getAdmins';
export const RESET_ADMIN_PASSWORD = 'user/resetAdminPassword';
export const START_FOLLOW_SERVICE = 'user/startFollowService';

export const SEND_FB_ALERT = 'notification/sendFirebaseNotification';
export const GET_PUSH_NOTIFICATIONS = 'notification/getPushNotifications';
export const DELETE_SCHEDULED_NOTIFICATION = 'notification/removeScheduleNotification';
export const EDIT_SCHEDULED_NOTIFICATION = 'notification/editScheduleNotification';

export const GET_INVENTORY_ITEMS = 'inventory/getInventory';
export const GET_INVENTORY_LEADS = 'inventory/getInventoryEnquiries';
export const ADD_INVENTORY_ITEM = 'inventory/addInventoryItems';

export const GET_CHATS = 'chat/getChats';
export const GET_MESSAGESS = 'chat/getMessages';

export const GET_TV_POSTS = 'tv/getTvPosts';
export const ADD_TV_POST = 'tv/addPost';
