import pages from './pages';
import memberPages from './memberPages';
import { getAdminRole } from 'utils/usersutils';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [getAdminRole() === 1 ? pages : memberPages]
};

export default menuItems;
