// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const ADD_PROFESSION_START = 'ADD_PROFESSION_START';
export const ADD_PROFESSION_SUCCESS = 'ADD_PROFESSION_SUCCESS';
export const ADD_PROFESSION_FAIL = 'ADD_PROFESSION_FAIL';

export const ADD_POST_TAGS_START = 'ADD_POST_TAGS_START';
export const ADD_POST_TAGS_SUCCESS = 'ADD_POST_TAGS_SUCCESS';
export const ADD_POST_TAGS_FAIL = 'ADD_POST_TAGS_FAIL';

export const ADD_PROFESSION_SERVICE_START = 'ADD_PROFESSION_SERVICE_START';
export const ADD_PROFESSION_SERVICE_SUCCESS = 'ADD_PROFESSION_SERVICE_SUCCESS';
export const ADD_PROFESSION_SERVICE_FAIL = 'ADD_PROFESSION_SERVICE_FAIL';

export const ADD_SERVICE_LOCATIONS_START = 'ADD_SERVICE_LOCATIONS_START';
export const ADD_SERVICE_LOCATIONS_SUCCESS = 'ADD_SERVICE_LOCATIONS_SUCCESS';
export const ADD_SERVICE_LOCATIONS_FAIL = 'ADD_SERVICE_LOCATIONS_FAIL';

export const CREATE_ZONE_START = 'CREATE_ZONE_START';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAIL = 'CREATE_ZONE_FAIL';

export const GET_ZONES_START = 'GET_ZONES_START';
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS';
export const GET_ZONES_FAIL = 'GET_ZONES_FAIL';

export const CREATE_LEAD_MASTER_ACTIVITY_START = 'CREATE_LEAD_MASTER_ACTIVITY_START';
export const CREATE_LEAD_MASTER_ACTIVITY_SUCCESS = 'CREATE_LEAD_MASTER_ACTIVITY_SUCCESS';
export const CREATE_LEAD_MASTER_ACTIVITY_FAIL = 'CREATE_LEAD_MASTER_ACTIVITY_FAIL';

export const GET_LEAD_MASTER_ACTIVITY_START = 'GET_LEAD_MASTER_ACTIVITY_START';
export const GET_LEAD_MASTER_ACTIVITY_SUCCESS = 'GET_LEAD_MASTER_ACTIVITY_SUCCESS';
export const GET_LEAD_MASTER_ACTIVITY_FAIL = 'GET_LEAD_MASTER_ACTIVITY_FAIL';

export const ADD_LOAN_PROVIDER_START = 'ADD_LOAN_PROVIDER_START';
export const ADD_LOAN_PROVIDER_SUCCESS = 'ADD_LOAN_PROVIDER_SUCCESS';
export const ADD_LOAN_PROVIDER_FAIL = 'ADD_LOAN_PROVIDER_FAIL';

export const GET_PROFESSIONS_START = 'GET_PROFESSIONS_START';
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS';
export const GET_PROFESSIONS_FAIL = 'GET_PROFESSIONS_FAIL';

export const GET_REPORTS_START = 'GET_REPORTS_START';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL';

export const ADD_LEAD_ACTIVITY_START = 'ADD_LEAD_ACTIVITY_START';
export const ADD_LEAD_ACTIVITY_SUCCESS = 'ADD_LEAD_ACTIVITY_SUCCESS';
export const ADD_LEAD_ACTIVITY_FAIL = 'ADD_LEAD_ACTIVITY_FAIL';

export const DELETE_LEAD_STATUS_START = 'DELETE_LEAD_STATUS_START';
export const DELETE_LEAD_STATUS_SUCCESS = 'DELETE_LEAD_STATUS_SUCCESS';
export const DELETE_LEAD_STATUS_FAIL = 'DELETE_LEAD_STATUS_FAIL';

export const UPDTAE_LEAD_ACTIVITY_STATUS_START = 'UPDTAE_LEAD_ACTIVITY_STATUS_START';
export const UPDTAE_LEAD_ACTIVITY_STATUS_SUCCESS = 'UPDTAE_LEAD_ACTIVITY_STATUS_SUCCESS';
export const UPDTAE_LEAD_ACTIVITY_STATUS_FAIL = 'UPDTAE_LEAD_ACTIVITY_STATUS_FAIL';

export const UPDATE_REPORT_STATUS_START = 'UPDATE_REPORT_STATUS_START';
export const UPDATE_REPORT_STATUS_SUCCESS = 'UPDATE_REPORT_STATUS_SUCCESS';
export const UPDATE_REPORT_STATUS_FAIL = 'UPDATE_REPORT_STATUS_FAIL';

export const GET_PROFESSION_SERVICES_START = 'GET_PROFESSION_SERVICES_START';
export const GET_PROFESSION_SERVICES_SUCCESS = 'GET_PROFESSION_SERVICES_SUCCESS';
export const GET_PROFESSION_SERVICES_FAIL = 'GET_PROFESSION_SERVICES_FAIL';

export const GET_SERVICE_LOCATIONS_START = 'GET_SERVICE_LOCATIONS_START';
export const GET_SERVICE_LOCATIONS_SUCCESS = 'GET_SERVICE_LOCATIONS_SUCCESS';
export const GET_SERVICE_LOCATIONS_FAIL = 'GET_SERVICE_LOCATIONS_FAIL';

export const GET_POST_TAGS_START = 'GET_POST_TAGS_START';
export const GET_POST_TAGS_SUCCESS = 'GET_POST_TAGS_SUCCESS';
export const GET_POST_TAGS_FAIL = 'GET_POST_TAGS_FAIL';

export const GET_LOAN_PROVIDERS_START = 'GET_LOAN_PROVIDERS_START';
export const GET_LOAN_PROVIDERS_SUCCESS = 'GET_LOAN_PROVIDERS_SUCCESS';
export const GET_LOAN_PROVIDERS_FAIL = 'GET_LOAN_PROVIDERS_FAIL';

export const GET_LEADS_START = 'GET_LEADS_START';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL';

export const GET_LEAD_DETAILS_START = 'GET_LEAD_DETAILS_START';
export const GET_LEAD_DETAILS_SUCCESS = 'GET_LEAD_DETAILS_SUCCESS';
export const GET_LEAD_DETAILS_FAIL = 'GET_LEAD_DETAILS_FAIL';

export const GET_DISCUSSION_POSTS_START = 'GET_DISCUSSION_POSTS_START';
export const GET_DISCUSSION_POSTS_SUCCESS = 'GET_DISCUSSION_POSTS_SUCCESS';
export const GET_DISCUSSION_POSTS_FAIL = 'GET_DISCUSSION_POSTS_FAIL';

export const GET_POSTS_START = 'GET_POSTS_START';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL';

export const GET_POSTS_GRID_START = 'GET_POSTS_GRID_START';
export const GET_POSTS_GRID_SUCCESS = 'GET_POSTS_GRID_SUCCESS';
export const GET_POSTS_GRID_FAIL = 'GET_POSTS_GRID_FAIL';

export const GET_REVIEWED_POSTS_START = 'GET_REVIEWED_POSTS_START';
export const GET_REVIEWED_POSTS_SUCCESS = 'GET_REVIEWED_POSTS_SUCCESS';
export const GET_REVIEWED_POSTS_FAIL = 'GET_REVIEWED_POSTS_FAIL';

export const VERIFY_POST_START = 'VERIFY_POST_START';
export const VERIFY_POST_SUCCESS = 'VERIFY_POST_SUCCESS';
export const VERIFY_POST_FAIL = 'VERIFY_POST_FAIL';


export const DISCUSSION_POST_REJECT_START = 'DISCUSSION_POST_REJECT_START';
export const DISCUSSION_POST_REJECT_SUCCESS = 'DISCUSSION_POST_REJECT_SUCCESS';
export const DISCUSSION_POST_REJECT_FAIL = 'DISCUSSION_POST_REJECT_FAIL';

export const ADD_DISCUSSION_REACT_START = 'ADD_DISCUSSION_REACT_START';
export const ADD_DISCUSSION_REACT_SUCCESS = 'ADD_DISCUSSION_REACT_SUCCESS';
export const ADD_DISCUSSION_REACT_FAIL = 'ADD_DISCUSSION_REACT_FAIL';

export const ADD_DISCUSSION_COMMENT_START = 'ADD_DISCUSSION_COMMENT_START';
export const ADD_DISCUSSION_COMMENT_SUCCESS = 'ADD_DISCUSSION_COMMENT_SUCCESS';
export const ADD_DISCUSSION_COMMENT_FAIL = 'ADD_DISCUSSION_COMMENT_FAIL';

export const DISCUSSION_COMMENTS_START = 'DISCUSSION_COMMENTS_START';
export const DISCUSSION_COMMENTS_SUCCESS = 'DISCUSSION_COMMENTS_SUCCESS';
export const DISCUSSION_COMMENTS_FAIL = 'DISCUSSION_COMMENTS_FAIL';

export const DISCUSSIONS_START = 'DISCUSSIONS_START';
export const DISCUSSIONS_SUCCESS = 'DISCUSSIONS_SUCCESS';
export const DISCUSSIONS_FAIL = 'DISCUSSIONS_FAIL';

export const CHANGE_DISCUSSION_STATUS_START = 'CHANGE_DISCUSSION_STATUS_START';
export const CHANGE_DISCUSSION_STATUS_SUCCESS = 'CHANGE_DISCUSSION_STATUS_SUCCESS';
export const CHANGE_DISCUSSION_STATUS_FAIL = 'CHANGE_DISCUSSION_STATUS_FAIL';

export const DISCUSSION_REPLIES_START = 'DISCUSSION_REPLIES_START';
export const DISCUSSION_REPLIES_SUCCESS = 'DISCUSSION_REPLIES_SUCCESS';
export const DISCUSSION_REPLIES_FAIL = 'DISCUSSION_REPLIES_FAIL';

export const ADD_DISCUSSION_REPLY_START = 'ADD_DISCUSSION_REPLY_START';
export const ADD_DISCUSSION_REPLY_SUCCESS = 'ADD_DISCUSSION_REPLY_SUCCESS';
export const ADD_DISCUSSION_REPLY_FAIL = 'ADD_DISCUSSION_REPLY_FAIL';

export const POST_REJECT_START = 'POST_REJECT_START';
export const POST_REJECT_SUCCESS = 'POST_REJECT_SUCCESS';
export const POST_REJECT_FAIL = 'POST_REJECT_FAIL';

export const VERIFY_DISCUSSION_POST_START = 'VERIFY_DISCUSSION_POST_START';
export const VERIFY_DISCUSSION_POST_SUCCESS = 'VERIFY_DISCUSSION_POST_SUCCESS';
export const VERIFY_DISCUSSION_POST_FAIL = 'VERIFY_DISCUSSION_POST_FAIL';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';


export const GET_USERS_FOR_AUTO_FOLLOW_START = 'GET_USERS_FOR_AUTO_FOLLOW_START';
export const GET_USERS_FOR_AUTO_FOLLOW_SUCCESS = 'GET_USERS_FOR_AUTO_FOLLOW_SUCCESS';
export const GET_USERS_FOR_AUTO_FOLLOW_FAIL = 'GET_USERS_FOR_AUTO_FOLLOW_FAIL';

export const GET_INTERNAL_USERS_START = 'GET_INTERNAL_USERS_START';
export const GET_INTERNAL_USERS_SUCCESS = 'GET_INTERNAL_USERS_SUCCESS';
export const GET_INTERNAL_USERS_FAIL = 'GET_INTERNAL_USERS_FAIL';

export const GET_INSIGHTS_START = 'GET_INSIGHTS_START';
export const GET_INSIGHTS_SUCCESS = 'GET_INSIGHTS_SUCCESS';
export const GET_INSIGHTS_FAIL = 'GET_INSIGHTS_FAIL';

export const GET_POST_DESIGN_INSIGHTS_START = 'GET_POST_DESIGN_INSIGHTS_START';
export const GET_POST_DESIGN_INSIGHTS_SUCCESS = 'GET_POST_DESIGN_INSIGHTS_SUCCESS';
export const GET_POST_DESIGN_INSIGHTS_FAIL = 'GET_POST_DESIGN_INSIGHTS_FAIL';

export const GET_ADMINS_START = 'GET_ADMINS_START';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL';

export const RESET_ADMIN_PASSWORD_START = 'RESET_ADMIN_PASSWORD_START';
export const RESET_ADMIN_PASSWORD_SUCCESS = 'RESET_ADMIN_PASSWORD_SUCCESS';
export const RESET_ADMIN_PASSWORD_FAIL = 'RESET_ADMIN_PASSWORD_FAIL';

export const START_FOLLOW_SERVICE_START = 'START_FOLLOW_SERVICE_START';
export const START_FOLLOW_SERVICE_SUCCESS = 'START_FOLLOW_SERVICE_SUCCESS';
export const START_FOLLOW_SERVICE_FAIL = 'START_FOLLOW_SERVICE_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';


export const SEND_FB_ALERT_START = 'SEND_FB_ALERT_START';
export const SEND_FB_ALERT_SUCCESS = 'SEND_FB_ALERT_SUCCESS';
export const SEND_FB_ALERT_FAIL = 'SEND_FB_ALERT_FAIL';

export const DELETE_SCHEDULED_NOTIFICATION_START = 'DELETE_SCHEDULED_NOTIFICATION_START';
export const DELETE_SCHEDULED_NOTIFICATION_SUCCESS = 'DELETE_SCHEDULED_NOTIFICATION_SUCCESS';
export const DELETE_SCHEDULED_NOTIFICATION_FAIL = 'DELETE_SCHEDULED_NOTIFICATION_FAIL';

export const EDIT_SCHEDULED_NOTIFICATION_START = 'EDIT_SCHEDULED_NOTIFICATION_START';
export const EDIT_SCHEDULED_NOTIFICATION_SUCCESS = 'EDIT_SCHEDULED_NOTIFICATION_SUCCESS';
export const EDIT_SCHEDULED_NOTIFICATION_FAIL = 'EDIT_SCHEDULED_NOTIFICATION_FAIL';

export const GET_PUSH_NOTIFICATIONS_START = 'GET_PUSH_NOTIFICATIONS_START';
export const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_FAIL = 'GET_PUSH_NOTIFICATIONS_FAIL';


export const CHATS_START = 'CHATS_START';
export const CHATS_SUCCESS = 'CHATS_SUCCESS';
export const CHATS_FAIL = 'CHATS_FAIL';
export const CHATS_CLEAR = 'CHATS_CLEAR';
export const CHATS_CLEAR_SUCCESS = 'CHATS_CLEAR_SUCCESS';

export const MESSAGES_START = 'MESSAGES_START';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_FAIL = 'MESSAGES_FAIL';
export const MESSAGES_CLEAR = 'MESSAGES_CLEAR';
export const MESSAGES_CLEAR_SUCCESS = 'MESSAGES_CLEAR_SUCCESS';

export const GET_TV_POSTS_START = 'GET_TV_POSTS_START';
export const GET_TV_POSTS_SUCCESS = 'GET_TV_POSTS_SUCCESS';
export const GET_TV_POSTS_FAIL = 'GET_TV_POSTS_FAIL';

export const ADD_TV_POST_START = 'ADD_TV_POST_START';
export const ADD_TV_POST_SUCCESS = 'ADD_TV_POST_SUCCESS';
export const ADD_TV_POST_FAIL = 'ADD_TV_POST_FAIL';

export const GET_INVENTORY_ITEMS_START = 'GET_INVENTORY_ITEMS_START';
export const GET_INVENTORY_ITEMS_SUCCESS = 'GET_INVENTORY_ITEMS_SUCCESS';
export const GET_INVENTORY_ITEMS_FAIL = 'GET_INVENTORY_ITEMS_FAIL';

export const GET_INVENTORY_LEADS_START = 'GET_INVENTORY_LEADS_START';
export const GET_INVENTORY_LEADS_SUCCESS = 'GET_INVENTORY_LEADS_SUCCESS';
export const GET_INVENTORY_LEADS_FAIL = 'GET_INVENTORY_LEADS_FAIL';

export const ADD_INVENTORY_ITEM_START = 'ADD_INVENTORY_ITEM_START';
export const ADD_INVENTORY_ITEM_SUCCESS = 'ADD_INVENTORY_ITEM_SUCCESS';
export const ADD_INVENTORY_ITEM_FAIL = 'ADD_INVENTORY_ITEM_FAIL';

export const GET_INQUIRIES_START = 'GET_INQUIRIES_START';
export const GET_INQUIRIES_SUCCESS = 'GET_INQUIRIES_SUCCESS';
export const GET_INQUIRIES_FAIL = 'GET_INQUIRIES_FAIL';